<template>
	<div class="flex-grow-1 d-flex flex-column">
		<div v-if="!loading" class="profile_form-center" style="width: 100%; max-width: 500px;">
			<div v-if="!deleteProfileVisible" >

				<h2 class="header-common"> {{ $t('profile.accountInfo') }}</h2>
				<avatar-selector :selectedAvatar="avatar" :selectedColor="avatarColor" @selectAvatar="onAvatarSelected" @selectColor="onAvatarColorSelected" />
				<div class="form-group" >
					<label for="name">{{ $t('register.name') }}:</label>
					<TextInput id="name" v-model="name" :msgs="msgs" class="form-control" maxlength="255" />
					<ComponentMessage :msgs="msgs" forComponent="name" />
				</div>
				<div class="form-group">
					<label for="username">{{ $t('register.username') }}:</label>
					<TextInput id="username" v-model="username" :msgs="msgs" class="form-control" maxlength="255" />
					<ComponentMessage :msgs="msgs" forComponent="username" />
				</div>
				<div class="form-group">
					<label for="email">{{ $t('profile.email') }}:</label>
					<EmailInput id="email" class="form-control" maxlength="255" readonly="true" v-model="email" />
					<ComponentMessage :msgs="msgs" forComponent="email" />
				</div>
				<div class="form-group">
					<label for="phone">{{ $t('profile.phone') }}:</label>
					<PhoneInput id="phone" v-model="phone" :msgs="msgs" class="form-control" maxlength="255"/>
					<ComponentMessage :msgs="msgs" forComponent="phone" />
				</div>
				<div class="form-group align-items-center" v-if="!registrationChannel || registrationChannel === 'NORMAL'">
					<Checkbox class="mr-2" id="showPasswordChangeFields" v-model="passwordChangeVisible" :binary="true" />
					<label for="showPasswordChangeFields" class="form-check-label">{{ $t('profile-view.want-to-change-password') }}</label>
				</div>


				<div v-if="passwordChangeVisible">
					<h3 class="header-common">{{ $t('profile-view.change-password') }}</h3>

					<div class="form-group">
						<label for="oldPassword">{{ $t('profile-view.current-password') }}</label>
						<PasswordInput id="oldPassword" v-model="oldPassword" :msgs="msgs" class="form-control"
							maxlength="255" />
						<ComponentMessage :msgs="msgs" forComponent="oldPassword" />
					</div>

					<div class="form-group">
						<label for="password">{{ $t('profile.password') }}:</label>
						<PasswordInput id="password" v-model="password" :msgs="msgs" class="form-control" maxlength="255" />
						<ComponentMessage :msgs="msgs" forComponent="password" />
					</div>

					<div class="form-group">
						<label for="password2">{{ $t('profile.password2') }}:</label>
						<PasswordInput id="password2" v-model="password2" :msgs="msgs" class="form-control" maxlength="255"
							:password1="password" />
						<ComponentMessage :msgs="msgs" forComponent="password2" />
					</div>
					<div class="form-group text-right">
						<small>{{ $t('profile.mandatoryLabel') }}</small>
					</div>
				</div>


				<div class="form-group buttonRow">
					<button @click="save()" class="btn btn-primary">
						<font-awesome-icon icon="check" />
						{{ $t('profile.saveButton') }}
					</button>
				</div>

			</div>

			<div class="card" v-if="deleteProfileVisible">
				<div class="card-header">{{ $t('profile-view.delete-profile') }}</div>
				<div class="card-body" v-html="this.$t('profile-view.profile-delete-message')">
				</div>
				<div class="card-footer">
					<button @click="deleteProfileVisible = false" class="btn btn-secondary mr-3">
						<font-awesome-icon icon="times" />
						{{ $t('cancel') }}
					</button>
					<button @click="deleteProfile()" class="btn btn-danger float-right">
						<font-awesome-icon icon="exclamation-triangle" />
						{{ $t('profile-view.delete-profile') }}
					</button>
				</div>
			</div>

			<div class="border-top mt-5">
				<div class="form-group text-center mt-5">
					<button @click="deleteProfileVisible = true" class="btn btn-danger" v-if="!deleteProfileVisible">
						<font-awesome-icon icon="exclamation-triangle" />
						{{ $t('profile-view.delete-profile') }}
					</button>
				</div>
			</div>
		</div>
		<LoadingSpinner v-if="loading" />
	</div>
</template>

<style>
.profile_form-center {
	max-width: 35rem !important;
	margin: auto;
}
</style>

<script>
import AvatarSelector from '@/components/profile/avatar/AvatarSelector.vue';
import Checkbox from 'primevue/checkbox';
import LoadingSpinner from "@/components/LoadingSpinner.vue";
export default {
  components: { LoadingSpinner, AvatarSelector, Checkbox },
	name: 'ProfileView',
	data() {
		return {
			passwordChangeVisible: false,
			deleteProfileVisible: false,
			name: '',
			username: '',
			email: '',
			phone: '',
			password: '',
			password2: '',
			oldPassword: '',

			/** @type {RegistrationChannel} */
			registrationChannel: "NORMAL",
			/** @type {string|null} */
			avatar: null,

			/** @type {string} */
			avatarColor: null,
			loading: true,
		};
	},
	props: {
		msgs: Object,
		loggedInUserData: Object
	},
	methods: {
		async save() {
			const success = await this.$rest.saveProfile({
				name:	this.name,
				username: this.username,
				email: this.email,
				phone: this.phone,
				password: this.password,
				password2: this.password2,
				oldPassword: this.oldPassword,
				avatar: this.avatar,
				avatarColor: this.avatarColor
			})
			if(success) {
				this.passwordChangeVisible = false;
				this.$emit("reloadUser")
				this.$emit("profileSaved")
				await this.loadUser();
			}
		},
		async loadUser() {
			const user = await this.$rest.getLoggedInUser();
			if(!user) return;
			this.name = user.name;
			this.email = user.email
			this.username = user.username
			this.phone = user.phone
			this.avatar = user.avatar || this.avatar;
			this.avatarColor = user.avatarColor || this.avatarColor;
			this.registrationChannel = user.registrationChannel || this.registrationChannel;
		},
		async deleteProfile() {
			if (!await this.$confirm(this.$t('profile-view.profile-delete-accept'))) {
				return;
			}
			const success = await this.$rest.deleteProfile();
			if(success) {
				this.$emit('logoutNoRest');
				await this.$router.replace('/');
			}
		},
		onAvatarSelected(avatar) {
			this.avatar = avatar;
		},
		onAvatarColorSelected(color) {
			this.avatarColor = color;
		}
	},
	async beforeMount() {
		this.loading = true;
		await this.loadUser()
		this.loading = false;
	},
	emits: ['reloadUser', 'profileSaved', 'logoutNoRest']
};
</script>
